import './loader.css'

function init(): void {
  document.getElementById('content')?.removeAttribute('hidden')
  document.getElementById('loader')?.setAttribute('hidden', '')
}

if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', init)
} else {
  init()
}
